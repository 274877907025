import { TreeItem, TreeItemButton } from '@air/component-tree';
import { Button } from '@air/primitive-button';
import { isEmpty, isUndefined } from 'lodash';
import Router from 'next/router';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { BoardsTree, BoardsTreeProps } from '~/components/CurrentWorkspaceNav/BoardsNav/BoardsTree/BoardsTree';
import { useGeneralLibraryTitle } from '~/components/LibraryBeta/hooks/useGeneralLibraryTitle';
import { GeneralLibraryItemActions } from '~/components/LibraryBeta/LibraryNavigation/components/LibraryNavigationGeneralItem/GeneralLibraryItemActions';
import { DNDBoardsRoot } from '~/components/Shared/Drag/DNDBoardsRoot';
import { Routes } from '~/constants/routes';
import { useBoardPermissionsCache } from '~/hooks/useBoardPermissionsCache';
import { useFetchObjectsPermissions } from '~/hooks/useFetchObjectsPermissions';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { centralizedBoardSelector } from '~/store/centralizedBoard/selectors';
import { isUrlActiveRouteSelector } from '~/store/router/selectors';
import { useRootWorkspaceBoards } from '~/swr-hooks/boards/useRootWorkspaceBoards';
import { useRearrangeBoards } from '~/swr-hooks/gallery/useGalleryRearrange';
import { GeneralLibrary } from '~/utils/librariesUtils';
import {
  canChangeBoardAssetsOrBoardsPosition,
  canChangeWorkspaceAssetsOrBoardsPosition,
  canCreateBoard,
} from '~/utils/permissions/boardPermissions';
import { useAirSelector } from '~/utils/ReduxUtils';

export const LibraryNavigationGeneralItem = memo(() => {
  const [isExpanded, setIsExpanded] = useState<boolean | undefined>(undefined);
  const { currentWorkspace } = useCurrentWorkspace();
  const { generalLibraryTitle } = useGeneralLibraryTitle();
  const {
    data: workspaceBoards,
    isLoadingMore,
    hasMore,
    isInitialLoading,
    loadNextPage,
  } = useRootWorkspaceBoards(currentWorkspace?.id);
  const centralizedBoard = useSelector(centralizedBoardSelector);
  const hasUserExplicitlyExpandedOrCollapsed = !isUndefined(isExpanded);
  const shouldShowBoards = isExpanded && !isEmpty(workspaceBoards);

  const isActive = useAirSelector((st) => isUrlActiveRouteSelector(st, Routes.generalLibrary));

  const { rearrangeBoards } = useRearrangeBoards();

  useFetchObjectsPermissions({
    objects: {
      boardIds: workspaceBoards.map((board) => board.id),
    },
  });

  const { getBoardPermissions } = useBoardPermissionsCache();
  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const canCreateRootBoards = canCreateBoard(permissions);

  /**
   * Automatically expand the general library if the centralized board is not
   * in a library and the user has not explicitly expanded or collapsed the
   * library since all non-library boards will exist under the general library.
   */
  useEffect(() => {
    if (
      (isActive || !centralizedBoard?.library || workspaceBoards?.some((board) => board.id === centralizedBoard?.id)) &&
      !hasUserExplicitlyExpandedOrCollapsed
    ) {
      setIsExpanded((prevState) => !prevState);
    }
  }, [centralizedBoard, hasUserExplicitlyExpandedOrCollapsed, isActive, workspaceBoards]);

  const onBoardRearrange: BoardsTreeProps['onBoardRearrange'] = useCallback(
    ({ adjacentItem, boards, parentBoardId, board }) =>
      rearrangeBoards({
        boards,
        draggedItem: board,
        adjacentItem,
        parentBoardId,
        library: undefined,
      }),
    [rearrangeBoards],
  );

  const getCanRearrange: BoardsTreeProps['getCanRearrange'] = useCallback(
    ({ parentBoardId }) =>
      parentBoardId
        ? canChangeBoardAssetsOrBoardsPosition(getBoardPermissions(parentBoardId))
        : canChangeWorkspaceAssetsOrBoardsPosition(permissions),
    [getBoardPermissions, permissions],
  );

  const prefix = useMemo(
    () => (
      <div className="flex size-6 items-center justify-center rounded bg-pigeon-50">
        <GeneralLibrary.Icon className="size-4 text-pigeon-500" />
      </div>
    ),
    [],
  );

  const suffix = useMemo(() => <GeneralLibraryItemActions />, []);

  const treeItemButton = useMemo(
    () => (
      <TreeItemButton data-testid="GENERAL_LIBRARY_NAV_ITEM" onClick={() => Router.push(Routes.generalLibrary)}>
        {generalLibraryTitle}
      </TreeItemButton>
    ),
    [generalLibraryTitle],
  );

  if (!workspaceBoards.length && !canCreateRootBoards) return null;

  return (
    <div>
      <DNDBoardsRoot>
        <TreeItem
          defaultOpen={false}
          onOpenChange={setIsExpanded}
          open={isExpanded}
          prefix={prefix}
          suffix={suffix}
          state={isActive ? 'active' : 'default'}
        >
          {treeItemButton}
        </TreeItem>
      </DNDBoardsRoot>

      {shouldShowBoards && (
        <div className="ml-[28px]">
          <BoardsTree
            enableAutoExpand={true}
            boardType="library"
            boards={workspaceBoards}
            parentBoardId={null}
            onBoardRearrange={onBoardRearrange}
            getCanRearrange={getCanRearrange}
          />

          {hasMore && !isInitialLoading && (
            <Button appearance="ghost" color="grey" onClick={() => loadNextPage()} size="small">
              {isLoadingMore ? 'Loading...' : 'Show more'}
            </Button>
          )}
        </div>
      )}
    </div>
  );
});

LibraryNavigationGeneralItem.displayName = 'LibraryNavigationGeneralItem';
