import { Close, Search } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { IconButton } from '@air/primitive-icon-button';
import { Input } from '@air/primitive-input';
import { type ChangeEventHandler, type KeyboardEventHandler, useCallback, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export type MobileSearchHeaderProps = {
  defaultValue?: string;
  onClear: () => void;
  onClose: () => void;
  onSubmit: (value: string) => void;
  onValueChange: (value: string) => void;
};

export const MobileSearchHeader = ({
  defaultValue,
  onClear,
  onClose,
  onSubmit,
  onValueChange,
}: MobileSearchHeaderProps) => {
  const [value, setValue] = useState(defaultValue);

  const [debouncedOnValueChange] = useDebouncedCallback((newValue: string) => {
    onValueChange(newValue);
  }, 300);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const newValue = event.target.value;

      setValue(newValue);
      debouncedOnValueChange(newValue);
    },
    [debouncedOnValueChange],
  );

  const handleSubmit = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === 'Enter' && value) {
        onSubmit?.(value);
      }
    },
    [onSubmit, value],
  );

  const handleClear = useCallback(() => {
    setValue('');
    debouncedOnValueChange('');
    onClear();
  }, [debouncedOnValueChange, onClear]);

  return (
    <div className="flex h-14 shrink-0 items-center gap-2 px-3">
      <Search className="size-6 text-grey-10" />
      <Input
        autoFocus
        className="grow border-0 bg-surface-1 px-0 focus:ring-0"
        defaultValue={defaultValue}
        onChange={handleChange}
        onKeyDown={handleSubmit}
        placeholder="Search"
        size="small"
        type="search"
        value={value}
      />
      {!!value && (
        <Button appearance="ghost" color="grey" onClick={handleClear} size="small">
          Clear
        </Button>
      )}
      <div className="h-6 w-px bg-grey-4" />
      <div className="flex items-center gap-3">
        <IconButton appearance="ghost" color="grey" icon={Close} onClick={onClose} label="Close" size="medium" />
      </div>
    </div>
  );
};
