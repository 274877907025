import { MemberAdd } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { Tooltip } from '@air/primitive-tooltip';

import { CantLeaveLibraryReason } from '~/components/LibraryBeta/hooks/useCanLeaveLibrary';

export const cantLeaveLibraryReasons: {
  [key in CantLeaveLibraryReason]: string;
} = {
  'sole-owner': 'You cannot leave this library as you are the only Library Owner.',
};

export type LibraryAdvancedSettingsLeaveLibraryProps = {
  onLeaveLibrary: () => void;
  cantLeaveLibraryReason: CantLeaveLibraryReason | undefined;
};

export const LibraryAdvancedSettingsLeaveLibrary = ({
  onLeaveLibrary,
  cantLeaveLibraryReason,
}: LibraryAdvancedSettingsLeaveLibraryProps) => {
  const button = (
    <Button
      disabled={!!cantLeaveLibraryReason}
      appearance="outline"
      color="grey"
      onClick={onLeaveLibrary}
      prefix={<MemberAdd className="size-4" />}
      data-testid="LIBRARY_SETTINGS_MODAL_LEAVE_BUTTON"
    >
      Leave library
    </Button>
  );

  return (
    <div>
      <h3 className="mb-2 text-10 font-bold uppercase tracking-wide text-grey-12">Leave library</h3>
      <p className="mb-4 text-12 text-grey-10">Remove library from sidebar and access to its contents</p>
      {!!cantLeaveLibraryReason ? (
        <Tooltip label={cantLeaveLibraryReasons[cantLeaveLibraryReason]}>{button}</Tooltip>
      ) : (
        button
      )}
    </div>
  );
};
