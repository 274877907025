import { Email, Lock, Team } from '@air/next-icons';
import { Label } from '@air/primitive-label';
import { RadioGroup, RadioGroupItem, RadioGroupProps } from '@air/primitive-radio-group';
import classNames from 'classnames';
import { useField } from 'formik';
import { ComponentProps } from 'react';

export type LibraryPrivacySettingsValue = 'invite-only' | 'public-to-workspace' | 'request-to-join';

export type LibraryPrivacySettingsProps = ComponentProps<'div'> & Required<Pick<RadioGroupProps, 'name'>>;

export const LibraryPrivacySettings = ({ className, name, ...restOfProps }: LibraryPrivacySettingsProps) => {
  const [_field, meta, helpers] = useField({ name });

  return (
    <div className={classNames('flex flex-col gap-3', className)} {...restOfProps}>
      <Label htmlFor="library-privacy-settings" size="small">
        Privacy
      </Label>

      <RadioGroup
        data-testid="LIBRARY_PRIVACY_SETTINGS"
        id="library-privacy-settings"
        name={name}
        onValueChange={helpers.setValue}
        value={meta.value}
      >
        <RadioGroupItem align="start" id="public-to-workspace" value="public-to-workspace">
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-1 text-14 font-medium text-grey-12">
              <Team className="size-4" />
              Any member can join
            </div>
            <div className="text-12 font-normal text-grey-10">Any workspace member can join this library</div>
          </div>
        </RadioGroupItem>

        <RadioGroupItem align="start" id="request-to-join" value="request-to-join">
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-1 text-14 font-medium text-grey-12">
              <Lock className="size-4" />
              Request to join
            </div>
            <div className="text-12 font-normal text-grey-10">
              Library owner or admin must approve a member’s request to join this library
            </div>
          </div>
        </RadioGroupItem>

        <RadioGroupItem align="start" id="hidden" value="invite-only">
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-1 text-14 font-medium text-grey-12">
              <Email className="size-4" />
              Invite-only
            </div>
            <div className="text-12 font-normal text-grey-10">
              A workspace member must be invited to join in order to see this library
            </div>
          </div>
        </RadioGroupItem>
      </RadioGroup>
    </div>
  );
};
