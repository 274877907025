import { Close, Gear, LogOut, TriangleDown, User } from '@air/next-icons';
import { ActionSheetOptionItem } from '@air/primitive-action-sheet';
import { Avatar } from '@air/primitive-avatar';
import { IconButton } from '@air/primitive-icon-button';
import { useAirModal } from '@air/provider-modal';
import Router from 'next/router';
import { memo, useMemo } from 'react';

import { WorkspacesActionSheet } from '~/components/CurrentWorkspaceNav/WorkspacesActionSheet';
import { ActionSheetModal } from '~/components/Mobile/ActionSheetModal';
import { Routes } from '~/constants/routes';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useLogout } from '~/swr-hooks/auth/useLogout';
import { canSeeWorkspaceMembers } from '~/utils/permissions/workspacePermissions';

const AVATAR_SIZE = 36;

const RETINA_DISPLAY_FACTOR = 3;

export type CoreMobileWorkspaceNavigationHeaderProps = {
  onClose?: () => void;
};

export const CoreMobileWorkspaceNavigationHeader = memo(({ onClose }: CoreMobileWorkspaceNavigationHeaderProps) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const [showWorkspacesActionSheet] = useAirModal(WorkspacesActionSheet);
  const [showActionSheet, hideActionSheet] = useAirModal(ActionSheetModal);
  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const { logout } = useLogout();
  const canViewMembers = canSeeWorkspaceMembers(permissions);

  const options = useMemo(() => {
    const optionsArray: ActionSheetOptionItem[] = [];

    if (!!canViewMembers) {
      optionsArray.push({
        id: 'workspace-settings',
        label: 'Workspace settings',
        onSelect: () => {
          Router.push(Routes.workspaceSettings.members);
          hideActionSheet();
        },
        prefix: <Gear className="size-4" />,
        type: 'item',
      });
    }

    optionsArray.push({
      id: 'account-settings',
      label: 'Account settings',
      onSelect: () => {
        Router.push(Routes.account.myAccount);
        hideActionSheet();
      },
      prefix: <User className="size-4" />,
      type: 'item',
    });

    optionsArray.push({
      id: 'logout',
      label: 'Log out',
      prefix: <LogOut className="size-4" />,
      onSelect: () => {
        logout();
        hideActionSheet();
      },
      type: 'item',
    });

    return optionsArray;
  }, [canViewMembers, hideActionSheet, logout]);

  if (!currentWorkspace) {
    return null;
  }

  return (
    <header className="flex h-16 items-center justify-between border-b border-b-grey-5 py-4 pl-1 pr-2">
      <button
        className="flex cursor-pointer items-center gap-4 rounded p-1 transition-colors hover:bg-grey-3"
        onClick={() => showWorkspacesActionSheet()}
      >
        <Avatar
          appearance="square"
          size={AVATAR_SIZE}
          src={`${currentWorkspace.image}?h=${AVATAR_SIZE * RETINA_DISPLAY_FACTOR}&w=${
            AVATAR_SIZE * RETINA_DISPLAY_FACTOR
          }=&fit=crop`}
        />

        <div className="flex items-center gap-1">
          <h2 className="text-16 font-semibold text-grey-11">{currentWorkspace.name}</h2>
          <TriangleDown className="size-4 text-grey-10" />
        </div>
      </button>
      <div className="flex items-center gap-4">
        <IconButton
          appearance="ghost"
          color="grey"
          icon={Gear}
          label="Settings"
          onClick={() =>
            showActionSheet({
              options,
              title: 'Settings',
            })
          }
          size="large"
        />
        {!!onClose && (
          <>
            <div className="h-8 w-px bg-grey-5" />
            <IconButton appearance="ghost" color="grey" icon={Close} label="Close" onClick={onClose} size="large" />
          </>
        )}
      </div>
    </header>
  );
});

CoreMobileWorkspaceNavigationHeader.displayName = 'CoreMobileWorkspaceNavigationHeader';
