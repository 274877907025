import { Gear as GearIcon } from '@air/next-icons';
import { DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { useAirModal } from '@air/provider-modal';
import { useMemo } from 'react';

import { GeneralLibrarySettingsModal } from '~/components/LibraryBeta/GeneralLibrarySettingsModal/GeneralLibrarySettingsModal';

export const useGeneralLibraryMenuOptions = () => {
  const [showGeneralLibrarySettingsModal] = useAirModal(GeneralLibrarySettingsModal);

  const menuOptions = useMemo(() => {
    const options: DropdownMenuOption[] = [
      {
        id: 'library-settings',
        label: 'Library settings',
        onSelect: () => showGeneralLibrarySettingsModal(),
        prefix: <GearIcon className="size-4" />,
        type: 'item',
      },
    ];

    return options;
  }, [showGeneralLibrarySettingsModal]);

  return { menuOptions };
};
