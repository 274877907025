import { CustomFieldType } from '@air/api/types';
import { capitalize } from 'lodash';
import { useMemo } from 'react';

import { customFieldIconMap } from '~/constants/customFieldIconMap';

interface CustomFieldTypeLabelProps {
  fieldTypeId: string;
  fieldTypes: CustomFieldType[];
}

export const CustomFieldTypeLabel = ({ fieldTypeId, fieldTypes }: CustomFieldTypeLabelProps) => {
  const fieldType = useMemo(() => {
    return fieldTypes.find((ft) => ft.id === fieldTypeId);
  }, [fieldTypeId, fieldTypes]);

  if (!fieldType) return null;
  const Icon = customFieldIconMap[fieldType.title];

  return (
    <div className="flex items-center gap-2">
      {Icon && <Icon className="size-4 text-grey-10" />}
      <div className="grow">
        <p className="text-14 text-grey-12">{capitalize(fieldType.title)}</p>
      </div>
    </div>
  );
};
