import { useTrackViewedLibrary } from '@air/analytics';
import { SidebarNavigationItem } from '@air/component-sidebar-navigation';
import { ImageSearch } from '@air/next-icons';
import Link from 'next/link';

import { Routes } from '~/constants/routes';
import { useIsActiveRoute } from '~/hooks/useIsActiveRoute';

export const SidebarNavigationItemAll = () => {
  const { isActiveRoute } = useIsActiveRoute({ route: Routes.media.all });
  const { trackViewedLibrary } = useTrackViewedLibrary();

  return (
    <SidebarNavigationItem
      asChild
      data-id="all"
      onClick={trackViewedLibrary}
      prefix={<ImageSearch className="size-4" />}
      state={isActiveRoute ? 'active' : 'default'}
    >
      <Link href={Routes.media.all}>All assets & files</Link>
    </SidebarNavigationItem>
  );
};
