import { Air } from '@air/next-icons';
import { VisuallyHidden } from '@air/primitive-visually-hidden';
import Link, { LinkProps } from 'next/link';
import React from 'react';

import { useHandleUnauthenticatedHomeLayoutUser } from '~/components/Layouts/HomeLayout/hooks/useHandleUnauthenticatedHomeLayoutUser.ts';
import { Routes } from '~/constants/routes';

import { useAccountProvisionerContext } from '../providers/AccountProvisionerProvider';
import { AccountProvisioningFailedState } from './AccountProvisioningFailedState';
import { SSOInProgress } from './SSOInProgress';

const LogoLink = (props: LinkProps & { className?: string }) => (
  <Link {...props}>
    <VisuallyHidden>Go back to landing page.</VisuallyHidden>
    <Air className="w-[76px] fill-blue-9" aria-hidden="true" />
  </Link>
);

type AccountProvisionLayoutProps = {
  children: React.ReactNode;
};

const Container = ({ children }: { children: React.ReactNode }) => (
  <section className="relative flex size-full items-center bg-[url('/assets/illustrations/swirl-grey-light.svg')] bg-cover bg-no-repeat text-center dark:bg-[url('/assets/illustrations/swirl-grey-dark.svg')]">
    <div className="m-0 box-border w-full min-w-0 md:min-h-screen">
      <header className="m-0 box-border flex h-[70px] w-full min-w-0 items-center justify-between border-t-2 border-solid border-teal-7">
        <LogoLink className="ml-6 md:ml-8" href={Routes.root} />
      </header>
      <div className="flex h-[calc(100vh-240px)] flex-col items-center justify-center p-5">{children}</div>
    </div>
  </section>
);

export const AccountProvisionLayout = ({ children }: AccountProvisionLayoutProps) => {
  const { inProgress, hasError, continuedWithAccountProvisioningError } = useAccountProvisionerContext();
  useHandleUnauthenticatedHomeLayoutUser();

  // If the account provisioner is in progress, show the in progress state
  if (inProgress) {
    return (
      <Container>
        <SSOInProgress />
      </Container>
    );
  }
  // If there is an error, show the error state
  if (hasError) {
    return continuedWithAccountProvisioningError ? (
      <>{children}</>
    ) : (
      <Container>
        <AccountProvisioningFailedState />
      </Container>
    );
  }

  // If we are here, it means that accountProvisioner is fetched and there is no error
  return <>{children}</>;
};
