import { Eye, EyeClosed } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { useCallback, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';

import { LibraryCustomField } from '~/components/LibraryBeta/hooks/queries/useLibraryCustomFields';
import { LibraryAdvancedSettingsCustomFieldsSelectListItem } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryAdvancedSettings/components/LibraryAdvancedSettingsCustomFieldsPopoverItem';
import { customFieldIconMap } from '~/constants/customFieldIconMap';

export type LibraryAdvancedSettingsCustomFieldsSelectListProps = {
  customFields: LibraryCustomField[];
  isLoading?: boolean;
  onApply: (customFields: LibraryCustomField[]) => void;
};

export const LibraryAdvancedSettingsCustomFieldsSelectList = ({
  customFields: _customFields,
  isLoading,
  onApply,
}: LibraryAdvancedSettingsCustomFieldsSelectListProps) => {
  const [customFields, setCustomFields] = useState<LibraryCustomField[]>(_customFields);
  const hasChanges = !isEqual(_customFields, customFields);

  const isShowAll = useMemo(() => {
    return customFields.every((customField) => customField.visible);
  }, [customFields]);

  const onUpdateVisiblity = useCallback(() => {
    setCustomFields((prevState) => {
      return prevState.map((customField) => {
        return {
          ...customField,
          visible: !isShowAll,
        };
      });
    });
  }, [isShowAll]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-1 p-1">
        {customFields.map((customField) => {
          const Icon = customFieldIconMap[customField.type.title];

          return (
            <LibraryAdvancedSettingsCustomFieldsSelectListItem
              key={customField.id}
              onClick={() => {
                setCustomFields((prevState) => {
                  return prevState.map((prevCustomField) => {
                    if (prevCustomField.id === customField.id) {
                      return {
                        ...prevCustomField,
                        visible: !prevCustomField.visible,
                      };
                    }

                    return prevCustomField;
                  });
                });
              }}
              prefix={<Icon className="size-4" />}
              suffix={customField.visible ? <Eye className="size-4" /> : <EyeClosed className="size-4" />}
            >
              {customField.name}
            </LibraryAdvancedSettingsCustomFieldsSelectListItem>
          );
        })}
      </div>

      <footer className="sticky bottom-0 flex justify-between gap-2 border-t border-t-grey-5 bg-surface-1 p-2">
        <Button
          appearance="ghost"
          color="grey"
          onClick={onUpdateVisiblity}
          prefix={isShowAll ? <EyeClosed className="size-4" /> : <Eye className="size-4" />}
          size="medium"
        >
          {isShowAll ? 'Hide all' : 'Show all'}
        </Button>

        <Button
          appearance="filled"
          color="blue"
          disabled={!hasChanges}
          isLoading={isLoading}
          onClick={() => onApply(customFields)}
          size="medium"
        >
          Apply
        </Button>
      </footer>
    </div>
  );
};
