import { WorkspaceNavImage } from '~/components/MainNavigation/WorkspacesNav/WorkspacesNavImage';
import { DragWorkspaceItem } from '~/components/Shared/Drag/dragTypes';

interface WorkspaceDragPreviewProps {
  item: DragWorkspaceItem;
}

export const WorkspaceDragPreview = ({ item: { image } }: WorkspaceDragPreviewProps) => {
  return <WorkspaceNavImage className="size-8 border-2 border-white shadow-popover" image={image} />;
};
