import { useTrackOpenedWorkspacePopover, useTrackViewedWorkspaceSettings } from '@air/analytics';
import { Skeleton } from '@air/component-skeleton';
import { Gear as GearIcon, MemberAdd as MemberAddIcon, Plus } from '@air/next-icons';
import {
  DropdownMenu,
  DropdownMenuOption,
  DropdownMenuSeparator,
  renderDropdownItems,
} from '@air/primitive-dropdown-menu';
import { useAirModal } from '@air/provider-modal';
import Router from 'next/router';
import { memo, useMemo } from 'react';

import { CurrentWorkspaceHeaderInfo } from '~/components/CurrentWorkspaceHeader/CurrentWorkspaceHeaderInfo';
import { CurrentWorkspaceHeaderTrigger } from '~/components/CurrentWorkspaceHeader/CurrentWorkspaceHeaderTrigger';
import { StorageUpgradeCTA } from '~/components/Upsells/StorageUpgradeCTA';
import { Routes } from '~/constants/routes';
import { CURRENT_WORKSPACE_HEADER_INFO } from '~/constants/testIDs';
import { useCreateWorkspace } from '~/hooks/useCreateWorkspace';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { useStorage } from '~/swr-hooks/subscriptions/useStorage';
import { useWorkspaceTotals } from '~/swr-hooks/workspaces/useWorkspaceTotals';
import { canAddWorkspaceMembers, canSeeWorkspaceMembers } from '~/utils/permissions/workspacePermissions';

import { InviteMembersModal } from '../Modals/InviteMembers/InviteMembersModal';
import { CurrentWorkspaceHeaderStorageMeter } from './CurrentWorkspaceHeaderStorageMeter';

export const CurrentWorkspaceHeaderDropdown = memo(() => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { data: totals } = useWorkspaceTotals();
  const { data: storage } = useStorage();
  const { data: subscription } = useSubscriptionContext();

  const [showInviteMembersModal] = useAirModal(InviteMembersModal);
  const { trackOpenedWorkspacePopover } = useTrackOpenedWorkspacePopover();
  const { trackViewedWorkspaceSettings } = useTrackViewedWorkspaceSettings();
  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const { createWorkspace } = useCreateWorkspace();

  const canViewMembers = canSeeWorkspaceMembers(permissions);
  const canEditMembers = canAddWorkspaceMembers(permissions);

  const options = useMemo(() => {
    const optionsArray: DropdownMenuOption[] = [];

    if (!!canEditMembers) {
      optionsArray.push({
        id: 'invite-members',
        label: 'Invite members',
        onSelect: () => showInviteMembersModal({ location: 'workspace-dropdown' }),
        prefix: <MemberAddIcon className="size-4" />,
        type: 'item',
      });
    }

    if (!!canViewMembers) {
      optionsArray.push({
        id: 'workspace-settings',
        label: 'Workspace settings',
        onSelect: () => {
          Router.push(Routes.workspaceSettings.members);
          trackViewedWorkspaceSettings({ location: 'workspace-popover' });
        },
        prefix: <GearIcon className="size-4" />,
        type: 'item',
      });
    }

    if (!!canEditMembers && !!canViewMembers) {
      optionsArray.push({
        id: 'seperator-1',
        type: 'separator',
      });
    }

    optionsArray.push({
      id: 'create-workspace',
      onSelect: createWorkspace,
      prefix: <Plus className="size-4" />,
      label: 'Join or create a workspace',
      type: 'item',
    });

    return optionsArray;
  }, [canEditMembers, canViewMembers, createWorkspace, showInviteMembersModal, trackViewedWorkspaceSettings]);

  return (
    <DropdownMenu
      className="w-[280px]"
      trigger={
        !!currentWorkspace ? (
          <CurrentWorkspaceHeaderTrigger
            className="max-w-[calc(100%-32px)]"
            onClick={trackOpenedWorkspacePopover}
            title={currentWorkspace?.name}
          />
        ) : (
          <Skeleton height={32} isText width={160} />
        )
      }
      size="large"
    >
      {totals && (
        <>
          <div className="-mx-2 px-3.5 pt-1" data-testid={CURRENT_WORKSPACE_HEADER_INFO}>
            <CurrentWorkspaceHeaderInfo />
            <CurrentWorkspaceHeaderStorageMeter />
            {(!!subscription?.isInFreePlan ||
              (subscription?.isOnProPlan && (!!storage?.isOutOfStorage || !!storage?.isAlmostOutOfStorage))) && (
              <StorageUpgradeCTA />
            )}
          </div>
          <DropdownMenuSeparator />
        </>
      )}
      {renderDropdownItems({ options })}
    </DropdownMenu>
  );
});

CurrentWorkspaceHeaderDropdown.displayName = 'CurrentWorkspaceHeaderDropdown';
