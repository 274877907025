import { TreeHeader } from '@air/component-tree';
import { Plus } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { IconButton } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import { useAirModal } from '@air/provider-modal';
import React, { memo, useCallback } from 'react';

import { BoardsNavLoadingSkeleton } from '~/components/CurrentWorkspaceNav/BoardsNav/BoardsNavLoadingSkeleton';
import { BoardsSortOptions } from '~/components/CurrentWorkspaceNav/BoardsNav/BoardsSortOptions';
import { BoardsTree, BoardsTreeProps } from '~/components/CurrentWorkspaceNav/BoardsNav/BoardsTree/BoardsTree';
import { useSidebarSectionCollapseState } from '~/components/CurrentWorkspaceNav/BoardsNav/BoardsTreeItem/hooks/useSidebarSectionCollapseState';
import { CreateBoardModal } from '~/components/Modals/CreateBoardModal';
import { DNDBoardsRoot } from '~/components/Shared/Drag/DNDBoardsRoot';
import { BOARDS_SELECT_EMPTY_OPTION } from '~/constants/BoardSearch';
import { SIDEBAR_BOARDS_COLLAPSED } from '~/constants/localStorageKeys';
import { CURRENT_WORKSPACE_NAV_CREATE_BOARD_BUTTON } from '~/constants/testIDs';
import { useBoardPermissionsCache } from '~/hooks/useBoardPermissionsCache';
import { useFetchObjectsPermissions } from '~/hooks/useFetchObjectsPermissions';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useRootWorkspaceBoards } from '~/swr-hooks/boards/useRootWorkspaceBoards';
import { useRearrangeBoards } from '~/swr-hooks/gallery/useGalleryRearrange';
import { useGetSubnavSortValue } from '~/swr-hooks/subnav/useSubnavSort';
import { useUpdateSubnavSort } from '~/swr-hooks/subnav/useUpdateSubnavSort';
import {
  canChangeBoardAssetsOrBoardsPosition,
  canChangeWorkspaceAssetsOrBoardsPosition,
  canCreateBoard,
} from '~/utils/permissions/boardPermissions';

const CREATE_BOARD_PLUS_BTN_TOOLTIP = 'Create new board';

export type WorkspaceBoardsProps = {
  scrollElement: HTMLDivElement;
};

export const WorkspaceBoards = memo(({ scrollElement }: WorkspaceBoardsProps) => {
  const { isCollapsed, handleToggleCollapse } = useSidebarSectionCollapseState({
    storageKey: SIDEBAR_BOARDS_COLLAPSED,
  });
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;
  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const [showCreateBoardModal] = useAirModal(CreateBoardModal);
  const canCreateRootBoards = canCreateBoard(permissions);
  const { canUpdateSubnavSort } = useUpdateSubnavSort();

  const {
    data: workspaceBoards,
    isLoadingMore: isLoadingMoreRoot,
    hasMore: hasMoreRoot,
    isInitialLoading: isInitialLoadingRoot,
    loadNextPage: loadMoreRoot,
  } = useRootWorkspaceBoards(workspaceId);

  useFetchObjectsPermissions({
    objects: {
      boardIds: workspaceBoards.map((board) => board.id),
    },
  });

  const { getBoardPermissions } = useBoardPermissionsCache();

  const { rearrangeBoards } = useRearrangeBoards();
  const { getSubNavSortValue } = useGetSubnavSortValue();

  const onBoardRearrange: BoardsTreeProps['onBoardRearrange'] = useCallback(
    ({ adjacentItem, boards, parentBoardId, board }) =>
      rearrangeBoards({
        boards,
        draggedItem: board,
        adjacentItem,
        parentBoardId,
      }),
    [rearrangeBoards],
  );

  const getCanRearrange: BoardsTreeProps['getCanRearrange'] = useCallback(
    ({ parentBoardId }) => {
      const isSubnavCustomSort = getSubNavSortValue()?.boardSort?.name === 'customSort';

      /**
       * If the subnav has been sorted and it's value isn't "Custom Sort",
       * don't allow them to rearrange the board since the order has been already set
       */
      if (!isSubnavCustomSort) {
        return false;
      }

      return parentBoardId
        ? canChangeBoardAssetsOrBoardsPosition(getBoardPermissions(parentBoardId))
        : canChangeWorkspaceAssetsOrBoardsPosition(permissions);
    },
    [getBoardPermissions, getSubNavSortValue, permissions],
  );

  return (
    <>
      <DNDBoardsRoot className="h-auto">
        <TreeHeader
          adornmentRight={
            <>
              {canUpdateSubnavSort && <BoardsSortOptions />}
              {canCreateRootBoards && (
                <Tooltip label={CREATE_BOARD_PLUS_BTN_TOOLTIP} side="right">
                  <IconButton
                    icon={Plus}
                    onClick={() => {
                      showCreateBoardModal({
                        selectedParentBoard: BOARDS_SELECT_EMPTY_OPTION,
                        trackLocation: 'side-nav-extension',
                      });
                    }}
                    size="small"
                    color="grey"
                    appearance="ghost"
                    className="mr-2"
                    data-testid={CURRENT_WORKSPACE_NAV_CREATE_BOARD_BUTTON}
                    label={CREATE_BOARD_PLUS_BTN_TOOLTIP}
                  />
                </Tooltip>
              )}
            </>
          }
          isExpanded={!isCollapsed}
          onTitleClick={handleToggleCollapse}
          title="Boards"
          showAdornmentRight
        />
      </DNDBoardsRoot>

      {!isCollapsed && (
        <>
          {isInitialLoadingRoot ? (
            <BoardsNavLoadingSkeleton sectionLinesData={[47, 68, 41, 60, 52]} />
          ) : (
            <BoardsTree
              boards={workspaceBoards}
              boardType="workspace"
              enableAutoExpand={true}
              id="boards"
              parentBoardId={null}
              onBoardRearrange={onBoardRearrange}
              getCanRearrange={getCanRearrange}
              scrollElement={scrollElement}
            />
          )}
        </>
      )}

      {hasMoreRoot && (
        <Button
          appearance="ghost"
          className="ml-6 justify-start"
          color="grey"
          isLoading={isLoadingMoreRoot}
          onClick={() => loadMoreRoot()}
          size="small"
        >
          Show more
        </Button>
      )}

      {!workspaceBoards.length && canCreateRootBoards && (
        <div className="ml-2.5">
          <Button
            data-testid="EMPTY_WORKSPACE_BOARDS_CREATE_BOARD_BUTTON"
            appearance="ghost"
            color="grey"
            onClick={() => showCreateBoardModal({ trackLocation: 'side-nav-extension' })}
            prefix={<Plus className="size-4" />}
            size="small"
          >
            Create a board
          </Button>
        </div>
      )}
    </>
  );
});

WorkspaceBoards.displayName = 'WorkspaceBoards';
