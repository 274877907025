import { tailwindVariants, VariantProps } from '@air/tailwind-variants';
import * as RadixProgress from '@radix-ui/react-progress';
import { useInView } from 'react-intersection-observer';

import { PROGRESS_BAR, PROGRESS_BAR_INDICATOR } from '~/constants/testIDs';

const progressBarVariants = tailwindVariants({
  base: 'w-full overflow-hidden rounded-full bg-blue-1 text-teal-7',
  variants: {
    color: {
      teal: 'bg-grey-3 text-teal-9',
      orange: 'bg-grey-3 text-yellow-7',
      red: 'bg-red-11 text-red-8',
    },
    size: {
      small: 'h-2',
      medium: 'h-4',
      large: 'h-6',
    },
  },
  defaultVariants: {
    color: 'teal',
    size: 'small',
  },
});

export type ProgressBarVariantsType = VariantProps<typeof progressBarVariants>;

export type ProgressBarProps = Pick<RadixProgress.ProgressProps, 'className' | 'value'> & ProgressBarVariantsType;

export const ProgressBar = ({ className, size, value = 0, color }: ProgressBarProps) => {
  const [ref, inView] = useInView({ triggerOnce: true });

  return (
    <RadixProgress.Root
      className={progressBarVariants({ size, color, class: className })}
      data-testid={PROGRESS_BAR}
      ref={ref}
      style={{
        transform: 'translateZ(0)',
      }}
      value={value}
    >
      <RadixProgress.Indicator
        className="size-full rounded-full bg-current ring-1 ring-grey-1"
        data-testid={PROGRESS_BAR_INDICATOR}
        style={{
          transition: 'transform 660ms cubic-bezier(0.65, 0, 0.35, 1)',
          transform: inView ? `translateX(-${100 - (value ?? 0)}%)` : 'translateX(-100%)',
        }}
      />
    </RadixProgress.Root>
  );
};
