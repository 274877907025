import type { LibraryWithPermissions } from '@air/api/types';
import { TreeItem, TreeItemAction, TreeItemButton, TreeItemProps } from '@air/component-tree';
import { EllipsisVertical as EllipsisVerticalIcon, Plus as PlusIcon } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { DropdownMenu } from '@air/primitive-dropdown-menu';
import { useAirModal } from '@air/provider-modal';
import classNames from 'classnames';
import { isEmpty, isUndefined } from 'lodash';
import Router from 'next/router';
import { memo, useCallback, useEffect, useState } from 'react';

import { BoardsTree, BoardsTreeProps } from '~/components/CurrentWorkspaceNav/BoardsNav/BoardsTree/BoardsTree';
import { useLibraryRootBoards } from '~/components/LibraryBeta/hooks/queries/useLibraryRootBoards';
import { useLibraryPermissions } from '~/components/LibraryBeta/hooks/useLibraryPermissions';
import { LibraryNaigationDropdownMenuContent } from '~/components/LibraryBeta/LibraryNavigation/components/LibraryNavigationDropdownMenuContent';
import { CreateBoardModal } from '~/components/Modals/CreateBoardModal';
import { DNDParentLibrary } from '~/components/Shared/Drag/DNDParentLibrary';
import { createLibraryRoute } from '~/constants/routes';
import { useBoardPermissionsCache } from '~/hooks/useBoardPermissionsCache';
import { useFetchObjectsPermissions } from '~/hooks/useFetchObjectsPermissions';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { isCentralizedBoardInCurrentLibrarySelector } from '~/store/centralizedBoard/selectors';
import { isUrlActiveRouteSelector } from '~/store/router/selectors';
import { useRearrangeBoards } from '~/swr-hooks/gallery/useGalleryRearrange';
import {
  canChangeBoardAssetsOrBoardsPosition,
  canChangeLibraryAssetsOrBoardsPosition,
  canCreateBoard,
} from '~/utils/permissions/boardPermissions';
import { useAirSelector } from '~/utils/ReduxUtils';

export type LibraryNavigationItemProps = Pick<TreeItemProps, 'open'> & {
  onOpenChange?: ({ isOpen, id }: { isOpen: boolean; id: LibraryWithPermissions['id'] }) => void;
  library: LibraryWithPermissions;
};

export const LibraryNavigationItem = memo(({ library, onOpenChange, open }: LibraryNavigationItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { currentWorkspace } = useCurrentWorkspace();
  const isCentralizedBoardInCurrentLibrary = useAirSelector((state) =>
    isCentralizedBoardInCurrentLibrarySelector(state, library.id),
  );
  const {
    data: libraryRootBoards,
    isLoadingMore,
    hasMore,
    isInitialLoading,
    loadNextPage,
  } = useLibraryRootBoards(open ? currentWorkspace?.id : undefined, library.id);
  const [setShowCreatePrivateBoardModal] = useAirModal(CreateBoardModal);

  const hasUserExplicitlyExpandedOrCollapsed = !isUndefined(open);

  const { libraryPermissions } = useLibraryPermissions({ libraryId: library.id });

  useFetchObjectsPermissions({
    objects: {
      boardIds: libraryRootBoards.map((board) => board.id),
    },
  });

  const { getBoardPermissions } = useBoardPermissionsCache();

  const canCreateLibraryBoard = canCreateBoard(libraryPermissions);

  const isActive = useAirSelector((st) => isUrlActiveRouteSelector(st, createLibraryRoute(library.id)));

  const { rearrangeBoards } = useRearrangeBoards();

  /**
   * Show the boards tree if the library is expanded and it contains boards.
   */
  const shouldShowBoards = open && !isEmpty(libraryRootBoards);

  /**
   * Show "Show more" button when the boards list is expanded and there are more
   * boards to be fetched.
   */
  const hasShowMoreButton = open && hasMore && !isInitialLoading;

  const onCreateBoard = useCallback(() => {
    setShowCreatePrivateBoardModal({
      initialLibrary: library,
      trackLocation: 'library',
    });
  }, [library, setShowCreatePrivateBoardModal]);

  /**
   * Automatically expand the library if it contains a centralized board and
   * the user has not explicitly expanded or collapsed the library.
   */
  useEffect(() => {
    if (isCentralizedBoardInCurrentLibrary && !hasUserExplicitlyExpandedOrCollapsed) {
      onOpenChange?.({
        id: library.id,
        isOpen: true,
      });
    }
  }, [isCentralizedBoardInCurrentLibrary, hasUserExplicitlyExpandedOrCollapsed, isActive, library.id, onOpenChange]);

  const onBoardRearrange: BoardsTreeProps['onBoardRearrange'] = useCallback(
    ({ adjacentItem, boards, parentBoardId, board }) =>
      rearrangeBoards({
        boards,
        draggedItem: board,
        adjacentItem,
        parentBoardId,
        library,
      }),
    [library, rearrangeBoards],
  );

  const getCanRearrange: BoardsTreeProps['getCanRearrange'] = useCallback(
    ({ parentBoardId }) =>
      parentBoardId
        ? canChangeBoardAssetsOrBoardsPosition(getBoardPermissions(parentBoardId))
        : canChangeLibraryAssetsOrBoardsPosition(libraryPermissions),
    [getBoardPermissions, libraryPermissions],
  );

  return (
    <div>
      <DNDParentLibrary dropLocation="board" library={library}>
        <TreeItem
          data-id="LIBRARY_TREE_ITEM"
          data-title={library.title}
          defaultOpen={false}
          onOpenChange={(isOpen) =>
            onOpenChange?.({
              id: library.id,
              isOpen,
            })
          }
          open={open}
          prefix={
            <div
              className="flex size-6 items-center justify-center rounded bg-pigeon-50"
              style={{ backgroundColor: library.color?.backgroundHex }}
            >
              {library.icon ?? '📚'}
            </div>
          }
          state={isActive ? 'active' : 'default'}
          suffix={
            <div
              className={classNames(
                'items-center group-focus-within/treeItem:flex group-hover/treeItem:flex group-active/treeItem:flex',
                isOpen ? 'flex' : 'hidden',
              )}
            >
              <DropdownMenu
                align="start"
                onOpenChange={setIsOpen}
                open={isOpen}
                trigger={
                  <TreeItemAction data-testid="LIBRARY_MENU_BUTTON">
                    <EllipsisVerticalIcon className="size-4" />
                  </TreeItemAction>
                }
              >
                <LibraryNaigationDropdownMenuContent library={library} />
              </DropdownMenu>

              {canCreateLibraryBoard && (
                <TreeItemAction data-testid="ADD_LIBRARY_BOARD_BUTTON" onClick={onCreateBoard}>
                  <PlusIcon className="size-4" />
                </TreeItemAction>
              )}
            </div>
          }
        >
          <TreeItemButton onClick={() => Router.push(createLibraryRoute(library.id))}>{library.title}</TreeItemButton>
        </TreeItem>
      </DNDParentLibrary>

      {shouldShowBoards && (
        <div className="ml-[28px]">
          <BoardsTree
            boardType="library"
            enableAutoExpand={true}
            boards={libraryRootBoards}
            parentBoardId={null}
            onBoardRearrange={onBoardRearrange}
            getCanRearrange={getCanRearrange}
          />

          {hasShowMoreButton && (
            <Button appearance="ghost" color="grey" onClick={() => loadNextPage()} size="small">
              {isLoadingMore ? 'Loading...' : 'Show more'}
            </Button>
          )}
        </div>
      )}
    </div>
  );
});

LibraryNavigationItem.displayName = 'LibraryNavigationItem';
