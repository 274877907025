import { Workspace } from '@air/api/types';
import { Team } from '@air/next-icons';
import { memo } from 'react';

import { LibraryDetailsAccessibility } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryDetails/components/LibraryDetailsAccessibility';
import { LibraryDetails } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryDetails/LibraryDetails';

interface GeneralLibraryInfoProps {
  workspaceName: Workspace['name'];
}

export const GeneralLibraryInfo = memo(({ workspaceName }: GeneralLibraryInfoProps) => {
  return (
    <LibraryDetails
      description={
        <div className="text-14 text-pigeon-600">
          This is the default library for the workspace where all members of {workspaceName} have access to its
          contents.
        </div>
      }
      accessibility={
        <LibraryDetailsAccessibility
          label="Everyone"
          icon={<Team className="size-4 fill-pigeon-500" />}
          description={`All members of ${workspaceName} have access to this library.`}
        />
      }
    />
  );
});

GeneralLibraryInfo.displayName = 'GeneralLibraryInfo';
