import { SidebarNavigationItem } from '@air/component-sidebar-navigation';
import { Star } from '@air/next-icons';
import Link from 'next/link';

import { Routes } from '~/constants/routes';
import { useIsActiveRoute } from '~/hooks/useIsActiveRoute';

export const SidebarNavigationItemFavorites = () => {
  const { isActiveRoute } = useIsActiveRoute({ route: Routes.media.favoriteItems });

  return (
    <SidebarNavigationItem
      asChild
      data-id="my-favorites"
      prefix={<Star className="size-4" />}
      state={isActiveRoute ? 'active' : 'default'}
    >
      <Link href={Routes.media.favoriteItems}>My favorites</Link>
    </SidebarNavigationItem>
  );
};
