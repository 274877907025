import { reportErrorToBugsnag } from '@air/utils-error';

import { isDevOrTestStage } from '~/swr-hooks/utils';

import { RecentlyDeletedItem, RecentlyDeletedResponse, RecentlyDeletedTableItem } from '../types';

export type UseRecentlyDeletedTableViewItemsParams = {
  data: RecentlyDeletedResponse[];
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
  isInitialLoading: boolean;
};

export const useRecentlyDeletedTableViewItems = (params: UseRecentlyDeletedTableViewItemsParams) => {
  const fetchNextPage = () => {
    if (params.data && params.hasNextPage && !params.isInitialLoading) {
      params.fetchNextPage();
    }
  };

  const itemData = params.data
    ? params.data.reduce<RecentlyDeletedTableItem[]>((acc, curr) => {
        curr.data.forEach((data) => {
          // We need this branching for TypeScript to understand the type of data

          if (data.itemType === 'board') {
            acc.push({
              type: 'board' as const,
              item: data as Extract<RecentlyDeletedItem, { itemType: 'board' }>,
            });
          } else if (data.itemType === 'library') {
            acc.push({
              type: 'library' as const,
              item: data as Extract<RecentlyDeletedItem, { itemType: 'library' }>,
            });
          } else if (data.itemType === 'asset') {
            acc.push({
              type: 'asset' as const,
              item: data as Extract<RecentlyDeletedItem, { itemType: 'asset' }>,
            });
          } else {
            const _exhaustiveCheck: never = data;
            const error = new Error(`Unknown item type: ${JSON.stringify(_exhaustiveCheck)}`);
            error.name = 'RecentlyDeleted.ItemType';

            if (isDevOrTestStage()) {
              throw error;
            } else {
              reportErrorToBugsnag({
                error,
                context: error.name,
              });
            }
          }
        });

        return acc;
      }, [])
    : [];

  return {
    data: itemData,
    fetchNextPage,
    hasNextPage: params.hasNextPage,
    isFetchingNextPage: params.isFetchingNextPage,
    isInitialLoading: params.isInitialLoading,
    isEmpty: !!params.data && !params.isInitialLoading && !params.data.some((items) => items.data.length > 0),
    total: params.data ? params.data[params.data.length - 1]?.total : 0,
  };
};
