import { EllipsisVertical, TriangleDown as TriangleDownIcon } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { DropdownMenu, DropdownMenuOption, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { IconButton } from '@air/primitive-icon-button';
import classNames from 'classnames';
import { memo, ReactNode } from 'react';

import { DropdownMenuWithSelectedLabelButton } from '~/components/Menus/DropdownMenuWithSelectedLabelButton';
import {
  CONTENT_COLLECTION_FORM_MODAL_FIELD_ELLIPSIS_BUTTON,
  CONTENT_COLLECTION_FORM_MODAL_FIELD_NAME,
  CONTENT_COLLECTION_FORM_MODAL_FIELD_REQUIREMENT_BUTTON,
} from '~/constants/testIDs';

interface CollectionFormFieldProps {
  fieldName: string;
  leftAdornment?: ReactNode;
  onSelect: (isRequired: boolean) => Promise<void>;
  required: boolean;
  ellipsisMenuOptions?: DropdownMenuOption[];
  disabled?: boolean;
}

export const CollectionFormField = memo(
  ({ fieldName, leftAdornment, required, ellipsisMenuOptions, onSelect, disabled }: CollectionFormFieldProps) => {
    return (
      <div className="mb-3 flex justify-between">
        <div
          className="flex max-w-[50%] items-center text-14 text-grey-11"
          data-testid={`${CONTENT_COLLECTION_FORM_MODAL_FIELD_NAME}-${fieldName}`}
        >
          {leftAdornment}
          {fieldName}
        </div>
        <div className="flex items-center gap-2">
          {disabled ? (
            <div
              data-testid={`${CONTENT_COLLECTION_FORM_MODAL_FIELD_REQUIREMENT_BUTTON}-${fieldName}`}
              className={classNames(
                'flex h-8 w-[118px] items-center justify-center text-14 font-semibold text-grey-11',
                ellipsisMenuOptions ? '' : 'mr-10',
              )}
            >
              Required
            </div>
          ) : (
            <DropdownMenuWithSelectedLabelButton
              selectedOption={required ? { id: 'required', label: 'Required' } : { id: 'optional', label: 'Optional' }}
              options={[
                {
                  id: 'optional',
                  label: 'Optional',
                  onSelect: () => onSelect(false),
                  type: 'item',
                },
                {
                  id: 'required',
                  label: 'Required',
                  onSelect: () => onSelect(true),
                  type: 'item',
                },
              ]}
              trigger={
                <Button
                  appearance="filled"
                  color="grey"
                  disabled={disabled}
                  size="medium"
                  className={classNames('w-[118px]', ellipsisMenuOptions ? '' : 'mr-10')}
                  data-testid={`${CONTENT_COLLECTION_FORM_MODAL_FIELD_REQUIREMENT_BUTTON}-${fieldName}`}
                  suffix={<TriangleDownIcon className="size-4" />}
                >
                  {required ? 'Required' : 'Optional'}
                </Button>
              }
            />
          )}
          {!!ellipsisMenuOptions && (
            <DropdownMenu
              trigger={
                <IconButton
                  label="Field options"
                  appearance="ghost"
                  color="grey"
                  icon={EllipsisVertical}
                  size="medium"
                  data-testid={`${CONTENT_COLLECTION_FORM_MODAL_FIELD_ELLIPSIS_BUTTON}-${fieldName}`}
                />
              }
            >
              {renderDropdownItems({ options: ellipsisMenuOptions })}
            </DropdownMenu>
          )}
        </div>
      </div>
    );
  },
);

CollectionFormField.displayName = 'CollectionFormField';
