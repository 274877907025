import { FC, ImgHTMLAttributes } from 'react';

import amex from '~/assets/credit-card-american-express.svg';
import mc from '~/assets/credit-card-master-card.svg';
import visa from '~/assets/credit-card-visa.svg';

export const CreditCardsIcons: FC<ImgHTMLAttributes<HTMLImageElement>> = () => (
  <div className="mb-1.5 flex gap-2">
    <img alt="American Express" className="size-4" src={amex} />
    <img alt="MasterCard" className="size-4" src={mc} />
    <img alt="Visa" className="size-4" src={visa} />
  </div>
);
