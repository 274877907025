import { FormikField } from '@air/formik-field';
import { FormikInput } from '@air/formik-input';
import { Button } from '@air/primitive-button';
import { Color } from '@air/primitive-color-picker';
import { Label } from '@air/primitive-label';
import { Textarea } from '@air/primitive-textarea';
import { getRandomColor, getRandomEmoji } from '@air/utils-libraries';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import {
  LibraryPrivacySettings,
  LibraryPrivacySettingsValue,
} from '~/components/LibraryBeta/LibraryPrivacySettings/LibraryPrivacySettings';
import { UpdateLibraryIcon } from '~/components/LibraryBeta/LibrarySettingsModal/components/UpdateLibraryIcon';

const LIBRARY_CREATE_FORM_SCHEMA = object({
  description: string(),
  color: object({
    hex: string(),
    id: string(),
    name: string(),
  }),
  icon: string(),
  privacy: string().oneOf(['invite-only', 'public-to-workspace', 'request-to-join']).required(),
  title: string().trim().required('Please enter library name'),
});

export type LibraryCreateModalFormValues = {
  color?: Color;
  description: string;
  icon?: string;
  privacy: LibraryPrivacySettingsValue;
  title: string;
};

export type LibraryCreateModalFormProps = {
  colors?: Color[];
  isLoading: boolean;
  onCreate: (values: LibraryCreateModalFormValues) => void;
};

export const LibraryCreateModalForm = ({ colors, isLoading, onCreate }: LibraryCreateModalFormProps) => {
  return (
    <Formik<LibraryCreateModalFormValues>
      enableReinitialize
      initialValues={{
        color: getRandomColor({ colors }),
        description: '',
        icon: getRandomEmoji(),
        privacy: 'public-to-workspace',
        title: '',
      }}
      onSubmit={onCreate}
      validationSchema={LIBRARY_CREATE_FORM_SCHEMA}
      isInitialValid={false}
    >
      {({ isValid }) => (
        <Form className="flex flex-col">
          <div className="mb-6 flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <Label htmlFor="create-library-form-title" size="small">
                Name
              </Label>
              <div className="flex gap-2">
                <UpdateLibraryIcon className="shrink-0" colors={colors} />
                <FormikInput className="w-full" id="create-library-form-title" name="title" />
              </div>
            </div>

            <FormikField
              id="create-library-form-description"
              label="Description"
              name="description"
              component={<Textarea placeholder="What is this library used for" />}
            />

            <LibraryPrivacySettings name="privacy" />
          </div>
          <div className="flex justify-end">
            <Button disabled={!isValid} isLoading={isLoading} type="submit">
              Create
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
