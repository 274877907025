import { TriangleDown } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { Popover, PopoverProps } from '@air/primitive-popover';

export type LibraryAdvancedSettingsCustomFieldsPopoverProps = Pick<PopoverProps, 'children' | 'onOpenChange'> & {
  isOpen: PopoverProps['open'];
  label: string;
};

export const LibraryAdvancedSettingsCustomFieldsPopover = ({
  children,
  isOpen,
  label = 'Show all',
  onOpenChange,
}: LibraryAdvancedSettingsCustomFieldsPopoverProps) => {
  return (
    <Popover
      className="w-[var(--radix-popover-trigger-width)] p-0"
      open={isOpen}
      onOpenChange={onOpenChange}
      trigger={
        <Button
          appearance="outline"
          className="w-full justify-between gap-1"
          color="grey"
          size="medium"
          suffix={<TriangleDown className="size-4" />}
        >
          {label}
        </Button>
      }
    >
      {children}
    </Popover>
  );
};
