import { LibraryWithPermissions } from '@air/api/types';
import { Skeleton } from '@air/component-skeleton';
import { Email } from '@air/next-icons';
import classNames from 'classnames';
import { isNull } from 'lodash';
import { forwardRef, useMemo } from 'react';

import { LibraryItem, LibraryItemProps } from '~/components/LibraryBeta/LibraryBrowseModal/components/LibraryItem';

export type LibraryItemPendingContainerProps = Pick<LibraryItemProps, 'className'> & {
  library: LibraryWithPermissions;
  stats: null | string[];
};

export const LibraryItemPendingContainer = forwardRef<HTMLDivElement, LibraryItemPendingContainerProps>(
  ({ className, library, stats }, ref) => {
    const meta = useMemo(() => {
      if (isNull(stats)) {
        return null;
      }

      return [
        <div key="0" className="mr-1 flex items-center gap-1 text-12 font-semibold text-pigeon-400">
          <Email className="size-4" />
          Pending
        </div>,
        ' • ',
        ...stats,
      ];
    }, [stats]);

    return (
      <LibraryItem
        className={classNames('border-b border-b-pigeon-100', className)}
        description={library.description}
        key={library.id}
        meta={meta || <Skeleton height={26} isText width={120} />}
        ref={ref}
        title={library.title}
      />
    );
  },
);

LibraryItemPendingContainer.displayName = 'LibraryItemContainer';
