import { SidebarNavigationItem } from '@air/component-sidebar-navigation';
import { Team } from '@air/next-icons';
import Link from 'next/link';
import { memo } from 'react';

import { Routes } from '~/constants/routes';
import { useIsActiveRoute } from '~/hooks/useIsActiveRoute';

export const SidebarNavigationItemPeople = memo(() => {
  const { isActiveRoute } = useIsActiveRoute({ route: Routes.people });

  return (
    <SidebarNavigationItem
      asChild
      data-id="people"
      prefix={<Team className="size-4" />}
      state={isActiveRoute ? 'active' : 'default'}
    >
      <Link href={Routes.people}>People</Link>
    </SidebarNavigationItem>
  );
});

SidebarNavigationItemPeople.displayName = 'SidebarNavigationItemPeople';
