import { useTrackActivatedIntercom, useTrackOpenIntercomChat } from '@air/analytics';
import { Help as HelpIcon } from '@air/next-icons';
import { DropdownMenu, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { Tooltip } from '@air/primitive-tooltip';
import { useAirModal } from '@air/provider-modal';
import { useEffect, useMemo } from 'react';

import { ChilipiperModal } from '~/components/Modals/ChilipiperModal';
import { Routes } from '~/constants/routes';
import { NEW_TAB } from '~/constants/urls';
import { type IdentifyParams, useCanny } from '~/providers/CannyProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { usePlanContext } from '~/providers/PlanProvider';
import { useWorkspaceMembersContext } from '~/providers/WorkspaceMembersProvider';
import { useCurrentWorkspaceMember } from '~/swr-hooks/members/useCurrentWorkspaceMember';
import { useStorage } from '~/swr-hooks/subscriptions/useStorage';
import { getHelpMenuOptions } from '~/utils/menuOptions/getHelpMenuOptions';

const HelpButton = () => {
  const [showChilipiperModal] = useAirModal(ChilipiperModal);
  const { trackActivatedIntercom } = useTrackActivatedIntercom();
  const { trackOpenIntercomChat } = useTrackOpenIntercomChat();
  const { canny, getCannyUrl } = useCanny();
  const { currentWorkspaceMember } = useCurrentWorkspaceMember();
  const { currentWorkspace } = useCurrentWorkspace();
  const { planMonthlyTotalPrice, planName } = usePlanContext();
  const { data: workspaceMembers } = useWorkspaceMembersContext();
  const { data: storage } = useStorage();

  const cannyParams = useMemo<Omit<IdentifyParams, 'appID'> | undefined>(() => {
    if (currentWorkspace && currentWorkspaceMember?.accountId) {
      return {
        user: {
          avatarURL: currentWorkspaceMember.avatar ?? '',
          email: currentWorkspaceMember.email,
          id: currentWorkspaceMember.accountId,
          name: `${currentWorkspaceMember.firstName} ${currentWorkspaceMember.lastName}`,
          companies: [
            {
              id: currentWorkspace.id,
              name: currentWorkspace.name,
              monthlySpend: planMonthlyTotalPrice ? planMonthlyTotalPrice / 100 : 0,
              customFields: {
                plan: planName,
                ['Total Workspace Members']: workspaceMembers?.length,
                ['Total Workspace Storage']: storage?.maxStorage,
              },
            },
          ],
        },
      };
    }
  }, [
    currentWorkspace,
    currentWorkspaceMember,
    planMonthlyTotalPrice,
    planName,
    storage?.maxStorage,
    workspaceMembers?.length,
  ]);

  const cannyUrl = useMemo(() => {
    return cannyParams ? getCannyUrl(cannyParams) : undefined;
  }, [cannyParams, getCannyUrl]);

  useEffect(() => {
    if (cannyParams) {
      canny?.identify(cannyParams);
    }
  }, [canny, cannyParams]);

  const menuOptions = getHelpMenuOptions({
    onChatWithSales: () => showChilipiperModal({}),
    onChatWithSupport: () => {
      trackActivatedIntercom({ location: 'main-nav' });
      trackOpenIntercomChat();
    },
    onHelpCenter: () => window.open(Routes.helpCenter, NEW_TAB),
    onLeaveFeedback: !!cannyUrl ? () => window.open(cannyUrl, NEW_TAB) : undefined,
  });

  return (
    <DropdownMenu
      trigger={
        <button className="text-grey-10 hover:text-grey-12">
          <Tooltip label="Help" side="right">
            <HelpIcon className="size-6" />
          </Tooltip>
        </button>
      }
    >
      {renderDropdownItems({ options: menuOptions })}
    </DropdownMenu>
  );
};

export default HelpButton;
