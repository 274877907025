import { Info } from '@air/next-icons';
import { Tooltip } from '@air/primitive-tooltip';
import { isUndefined } from 'lodash';

import { ProgressBar, ProgressBarVariantsType } from '~/components/ProgressBar/ProgressBar';
import { STORAGE_METER_NUMBERS } from '~/constants/testIDs';

export interface WorkspaceStorageMeterProps {
  percentOfStorageUsed?: number;
  showPercentage?: boolean;
  formattedUsedBytes?: string;
  formattedMaxBytes?: string;
  progressColor: ProgressBarVariantsType['color'];
}

export const WorkspaceStorageMeter = ({
  percentOfStorageUsed,
  formattedUsedBytes = '0 GB',
  formattedMaxBytes,
  progressColor,
  showPercentage = false,
}: WorkspaceStorageMeterProps) => {
  if (isUndefined(percentOfStorageUsed)) return null;

  const percent = percentOfStorageUsed > 100 ? (100 / percentOfStorageUsed) * 100 : percentOfStorageUsed;

  return (
    <div className="flex flex-col items-start justify-start">
      <div className="text-12 font-bold uppercase tracking-wide text-pigeon-400">Workspace Storage</div>
      <div className="my-2 w-full">
        <ProgressBar size="small" color={progressColor} value={percent ?? 0} />
      </div>
      <div className="flex items-center gap-2 text-12 text-pigeon-400" data-testid={STORAGE_METER_NUMBERS}>
        <span>
          {`Using ${formattedUsedBytes} of ${formattedMaxBytes}${
            showPercentage && !!percentOfStorageUsed ? ` (${percentOfStorageUsed}%)` : ''
          }`}
        </span>
        <Tooltip className="max-w-[200px]" label="Please note that this total may take a few minutes to update.">
          <Info className="size-4" />
        </Tooltip>
      </div>
    </div>
  );
};
