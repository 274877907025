import { SidebarNavigationItem } from '@air/component-sidebar-navigation';
import { Lifeline } from '@air/next-icons';
import Link from 'next/link';

import { Routes } from '~/constants/routes';
import { useIsActiveRoute } from '~/hooks/useIsActiveRoute';

export const SidebarNavigationItemRecentlyModified = () => {
  const { isActiveRoute } = useIsActiveRoute({ route: Routes.media.recentlyModified });

  return (
    <SidebarNavigationItem
      asChild
      data-id="recently-modified"
      prefix={<Lifeline className="size-4" />}
      state={isActiveRoute ? 'active' : 'default'}
    >
      <Link href={Routes.media.recentlyModified}>Recently modified</Link>
    </SidebarNavigationItem>
  );
};
