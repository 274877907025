import { InfiniteData } from '@tanstack/react-query';
import { memo, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useUnmount } from 'react-use';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useSocketConnectionChange } from '~/providers/SocketContext/hooks/useSocketConnectionChange';
import { removeTasksAction } from '~/store/tasks/actions';
import { makeRestoreLibraryTasksByWorkspaceId } from '~/store/tasks/selectors';
import { queryClient } from '~/swr-hooks/queryClient';
import { ReadyState } from '~/types/sockets';
import { useAirSelector } from '~/utils/ReduxUtils';
import { useCancelTask } from '~/utils/taskUtils/useCancelTask';
import { useHideTask } from '~/utils/taskUtils/useHideTask';
import { usePrivateSyncTasks } from '~/utils/taskUtils/usePrivateSyncTasks';

import { PaneContainer } from '../FileStatusTrackingPane/PaneContainer';
import { isLibariesKey } from '../LibraryBeta/hooks/queries/useLibraries';
import { getRecentlyDeletedKey } from '../RecentlyDeleted/hooks/queries/useRecentlyDeleted';
import { RecentlyDeletedResponse } from '../RecentlyDeleted/types';
import { RestoreLibraryCompletedPanelItem } from './RestoreLibraryTaskCompletedPanelItem';
import { RestoreLibraryFailedPanelItem } from './RestoreLibraryTaskFailedPanelItem';
import { RestoreLibraryPanelInProgressPanelItem } from './RestoreLibraryTaskInProgressPanelItem';

export const PrivateRestoreLibraryTasksPanel = memo(() => {
  const { currentWorkspace } = useCurrentWorkspace();
  const tasksSelector = useMemo(
    () => makeRestoreLibraryTasksByWorkspaceId(currentWorkspace?.id),
    [currentWorkspace?.id],
  );
  const dispatch = useDispatch();
  const restoreLibraryTasks = useAirSelector(tasksSelector);
  const { cancelTask } = useCancelTask();
  const { hideTask } = useHideTask();

  const { loadFromStorage, syncLocalTasks } = usePrivateSyncTasks({
    workspaceId: currentWorkspace?.id,
    tasksSelector,
    localType: 'LibraryRestoration',
    remoteType: 'ContentRestorer',
    onComplete: async ({ task }) => {
      const workspaceId = currentWorkspace?.id;

      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      const recentlyDeletedKey = getRecentlyDeletedKey({ workspaceId });
      queryClient.setQueriesData<InfiniteData<RecentlyDeletedResponse>>(
        {
          queryKey: recentlyDeletedKey,
        },
        (oldData) => {
          if (!oldData) {
            return oldData;
          }

          return {
            ...oldData,
            pages: oldData.pages.map((page) => {
              return {
                ...page,
                data: page.data.filter(
                  (item) => item.itemType !== 'library' || task.data?.restoredLibraryId !== item.library.id,
                ),
              };
            }),
          };
        },
      );
      queryClient.invalidateQueries({
        queryKey: recentlyDeletedKey,
      });

      queryClient.invalidateQueries({
        predicate: ({ queryKey }) => {
          return isLibariesKey(queryKey) && queryKey[1].workspaceId === currentWorkspace?.id;
        },
      });
    },
  });

  /**
   * On mount, take the tasks from local storage and add them to Redux
   */
  useEffect(() => {
    if (currentWorkspace?.id) {
      loadFromStorage();
    }
  }, [loadFromStorage, currentWorkspace?.id]);

  /**
   * Because sockets reconnect when the user's internet connection comes back online,
   * we don't need an explicit isOnline check but can piggyback off of the socket reconnecting
   */
  useSocketConnectionChange({
    onChange: (readyState) => {
      if (readyState === ReadyState.OPEN) {
        syncLocalTasks();
      }
    },
  });

  /**
   * When this panel unmounts (user logs out most likely),
   * reset the state
   */
  useUnmount(() => {
    dispatch(
      removeTasksAction({
        taskIds: restoreLibraryTasks.map(({ localTaskId }) => localTaskId),
      }),
    );
  });

  if (restoreLibraryTasks.length < 1) {
    return null;
  }

  return (
    <div>
      {restoreLibraryTasks.map((duplicateLibraryTask) => {
        return (
          <PaneContainer key={duplicateLibraryTask.localTaskId} className="mb-2 last:mb-0">
            {!duplicateLibraryTask.hidden && duplicateLibraryTask.status === 'in-progress' && (
              <RestoreLibraryPanelInProgressPanelItem
                onCancel={() => hideTask(duplicateLibraryTask.localTaskId)}
                {...duplicateLibraryTask}
              />
            )}
            {duplicateLibraryTask.status === 'completed' && (
              <RestoreLibraryCompletedPanelItem
                onClear={() => cancelTask(duplicateLibraryTask.localTaskId)}
                {...duplicateLibraryTask}
              />
            )}
            {duplicateLibraryTask.status === 'error' && (
              <RestoreLibraryFailedPanelItem
                onClear={() => cancelTask(duplicateLibraryTask.localTaskId)}
                {...duplicateLibraryTask}
              />
            )}
          </PaneContainer>
        );
      })}
    </div>
  );
});

PrivateRestoreLibraryTasksPanel.displayName = 'PrivateDuplicateLibraryTasksPanel';
