import { SidebarNavigationItem } from '@air/component-sidebar-navigation';
import { Trash } from '@air/next-icons';
import { Tooltip } from '@air/primitive-tooltip';
import Link from 'next/link';

import { Routes } from '~/constants/routes';
import { useIsActiveRoute } from '~/hooks/useIsActiveRoute';

export const SidebarNavigationItemRecentlyDeleted = () => {
  const { isActiveRoute } = useIsActiveRoute({ route: Routes.media.recentlyDeleted });

  return (
    <Tooltip label="Recently deleted by you" side="right" sideOffset={4}>
      <SidebarNavigationItem
        asChild
        data-id="recently-deleted"
        prefix={<Trash className="size-4" />}
        state={isActiveRoute ? 'active' : 'default'}
      >
        <Link href={Routes.media.recentlyDeleted}>Recently deleted</Link>
      </SidebarNavigationItem>
    </Tooltip>
  );
};
