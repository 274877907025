import { NotificationCustomFieldsMultiselectValues } from '~/components/AirNotifications/components/NotificationCustomFieldsMultiselectValues';
import { NotificationCustomFieldValue } from '~/components/AirNotifications/components/NotificationCustomFieldValue';
import {
  isSingleValueSet,
  isSingleValueUnset,
  NotificationCustomFieldType,
} from '~/components/AirNotifications/utils/types';
import { customFieldIconMap } from '~/constants/customFieldIconMap';
import { usePrivateCustomFieldsContext } from '~/providers/PrivateCustomFieldsProvider';

export type NotificationCustomFieldsProps = {
  customFields: NotificationCustomFieldType[];
};

export const NotificationCustomFields = ({ customFields }: NotificationCustomFieldsProps) => {
  const { customFields: customFieldsContext } = usePrivateCustomFieldsContext();

  return (
    <div className="flex flex-col gap-3">
      {customFields.map((customField) => {
        const Icon = customFieldIconMap[customField.type.title];
        const customFieldTitle = customFieldsContext?.find((cField) => cField.id === customField.id)?.name;

        return (
          <div className="flex flex-col gap-1" data-id={customField.id} key={customField.id}>
            <div className="flex items-center gap-1 p-1 pl-0 text-pigeon-400">
              <Icon className="size-4" />
              <span className="text-12 font-medium">{customFieldTitle}</span>
            </div>
            <div className="flex gap-1">
              {isSingleValueSet(customField) && <NotificationCustomFieldValue state="set" customField={customField} />}
              {isSingleValueUnset(customField) && <p className="text-12 text-grey-8">None</p>}

              {(customField.set?.values || customField.unset?.values) && (
                <NotificationCustomFieldsMultiselectValues
                  newValues={customField.set?.values ?? []}
                  removedValues={customField.unset?.values ?? []}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
