import { Airror } from '@air/errors';
import { isAirrorInstance } from '@air/utils-error';

/**
 * This is a helper function to call Air API - it returns data or throws if response is Airror.
 */
export const callAirApi = async <T>(apiCall: () => Promise<Airror<any> | T>): Promise<T> => {
  const data = await apiCall();

  if (isAirrorInstance(data)) {
    throw data;
  }

  return data;
};

// Utility type to extract the response types from the SDK functions
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ApiResponse<T extends (args: any) => Promise<any>> = Extract<
  Awaited<ReturnType<T>>,
  { id: string } | { data: object }
>;
