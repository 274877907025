import { BaseCustomField, FormFieldInput } from '@air/api/types';
import { DropdownMenuOption } from '@air/primitive-dropdown-menu';

import { customFieldIconMap } from '~/constants/customFieldIconMap';

import { getDropdownMenuOption } from './getDropdownMenuOption';

export interface GetContentCollectionCustomFieldsMenuOptionsParams {
  fieldsWithNames?: (FormFieldInput & Pick<BaseCustomField, 'name'>)[];
  workspaceCustomFields?: BaseCustomField[];
  addFieldToForm: (id: string, name: string) => void;
  addNewField: () => void;
  canCreateCustomFields: boolean;
}
export const getContentCollectionCustomFieldsMenuOptions = ({
  fieldsWithNames,
  workspaceCustomFields,
  addFieldToForm,
  addNewField,
  canCreateCustomFields,
}: GetContentCollectionCustomFieldsMenuOptionsParams) => {
  const fieldMenuOptions = workspaceCustomFields?.reduce((acc, curr) => {
    if (!fieldsWithNames?.some((addedField) => addedField.customFieldId === curr.id)) {
      const Icon = customFieldIconMap[curr.type.title];
      acc.push({
        id: curr.id,
        label: curr.name,
        onSelect: () => addFieldToForm(curr.id, curr.name),
        prefix: (
          <div className="flex text-pigeon-400">
            <Icon className="size-4" />
          </div>
        ),
        type: 'item',
      });
    }
    return acc;
  }, [] as DropdownMenuOption[]);

  if (canCreateCustomFields) {
    if (!!workspaceCustomFields?.length && workspaceCustomFields?.length !== fieldsWithNames?.length) {
      fieldMenuOptions?.push({
        id: 'create-custom-field-separator',
        type: 'separator',
      });
    }

    fieldMenuOptions?.push({
      ...getDropdownMenuOption('create-custom-field'),
      onSelect: addNewField,
      type: 'item',
    });
  }

  return fieldMenuOptions;
};
