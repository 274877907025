import { useTrackViewedWorkspaceSettings } from '@air/analytics';
import { SidebarNavigationItem } from '@air/component-sidebar-navigation';
import { Gear } from '@air/next-icons';
import Link from 'next/link';

import { Routes } from '~/constants/routes';
import { useIsActiveRoute } from '~/hooks/useIsActiveRoute';

export const SidebarNavigationItemWorkspaceSettings = () => {
  const { isActiveRoute } = useIsActiveRoute({ route: Routes.workspaceSettings.root });
  const { trackViewedWorkspaceSettings } = useTrackViewedWorkspaceSettings();

  return (
    <SidebarNavigationItem
      asChild
      data-id="workspace-settings"
      onClick={() => trackViewedWorkspaceSettings({ location: 'current-workspace-nav' })}
      prefix={<Gear className="size-4" />}
      state={isActiveRoute ? 'active' : 'default'}
    >
      <Link href={Routes.workspaceSettings.members}>Settings & members</Link>
    </SidebarNavigationItem>
  );
};
