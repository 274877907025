import { SidebarNavigationItem } from '@air/component-sidebar-navigation';
import { Images } from '@air/next-icons';
import { useAirModal } from '@air/provider-modal';

import { LibraryBrowseModal } from '~/components/LibraryBeta/LibraryBrowseModal/LibraryBrowseModal';

export const SidebarNavigationItemBrowseLibraries = () => {
  const [showLibraryBrowseModal] = useAirModal(LibraryBrowseModal);

  return (
    <SidebarNavigationItem
      data-id="browse-libraries"
      onClick={showLibraryBrowseModal}
      prefix={<Images className="size-4" />}
    >
      <span className="truncate">Browse libraries</span>
    </SidebarNavigationItem>
  );
};
