import { LibraryWithPermissions } from '@air/api/types';
import {
  Gear as GearIcon,
  LogOut as LogOutIcon,
  MemberAdd as MemberAddIcon,
  Trash as TrashIcon,
} from '@air/next-icons';
import { DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { useAirModal } from '@air/provider-modal';
import { useMemo } from 'react';

import { useLibrary } from '~/components/LibraryBeta/hooks/queries/useLibrary';
import { useLibraryPermissions } from '~/components/LibraryBeta/hooks/useLibraryPermissions';
import { LibraryDeleteConfirmationModal } from '~/components/LibraryBeta/LibraryDeleteConfirmationModal/LibraryDeleteConfirmationModal';
import { LibraryLeaveModal } from '~/components/LibraryBeta/LibraryLeaveModal/LibraryLeaveModal';
import { LibrarySettingsModal } from '~/components/LibraryBeta/LibrarySettingsModal/LibrarySettingsModal';
import {
  canAddOthersToPrivateOrHiddenLibrary,
  canAddOthersToPublicLibrary,
  canDeleteLibrary,
} from '~/utils/permissions/libraryPermissions';

export type UseLibraryMenuOptionsParams = {
  initialData?: LibraryWithPermissions;
  libraryId: LibraryWithPermissions['id'];
};

export const useLibraryMenuOptions = ({ initialData, libraryId }: UseLibraryMenuOptionsParams) => {
  const { libraryPermissions } = useLibraryPermissions({ libraryId });

  const [setShowLibrarySettingsModal] = useAirModal(LibrarySettingsModal);
  const [setShowLibraryDeleteConfirmationModal] = useAirModal(LibraryDeleteConfirmationModal);
  const [setShowLibraryLeaveModal] = useAirModal(LibraryLeaveModal);
  const { data: library } = useLibrary({ initialData, libraryId });

  const canDelete = canDeleteLibrary(libraryPermissions);
  const canInvite =
    library?.accessibility === 'public'
      ? canAddOthersToPublicLibrary(libraryPermissions)
      : canAddOthersToPrivateOrHiddenLibrary(libraryPermissions);

  const menuOptions = useMemo(() => {
    if (!library) return [];

    const options: DropdownMenuOption[] = [
      {
        id: 'library-settings',
        label: 'Library settings',
        onSelect: () =>
          setShowLibrarySettingsModal({
            defaultTab: 'details',
            libraryId,
          }),
        prefix: <GearIcon className="size-4" />,
        type: 'item',
      },
    ];

    if (canInvite) {
      options.push({
        id: 'invite-members',
        label: 'Invite members',
        onSelect: () =>
          setShowLibrarySettingsModal({
            defaultTab: 'members',
            libraryId,
          }),
        prefix: <MemberAddIcon className="size-4" />,
        type: 'item',
      });
    }

    /**
     * RoleId exists only if they are a member of the library
     */
    if (library.roleId) {
      options.push({
        id: 'leave-library',
        label: 'Leave library',
        onSelect: () => setShowLibraryLeaveModal({ id: library.id, title: library.title }),
        prefix: <LogOutIcon className="size-4" />,
        type: 'item',
      });
    }

    if (canDelete) {
      options.push({
        type: 'separator',
        id: 'separator',
      });
      options.push({
        className: '!text-red-11',
        id: 'delete-library',
        label: 'Delete library',
        onSelect: () =>
          setShowLibraryDeleteConfirmationModal({
            libraryId,
            name: library.title,
          }),
        prefix: <TrashIcon className="size-4" />,
        type: 'item',
      });
    }

    return options;
  }, [
    library,
    canInvite,
    canDelete,
    setShowLibrarySettingsModal,
    libraryId,
    setShowLibraryLeaveModal,
    setShowLibraryDeleteConfirmationModal,
  ]);

  return { menuOptions };
};
