import { useTrackCompletedDragging } from '@air/analytics';
import { Board } from '@air/api/types';
import { useAirModal } from '@air/provider-modal';
import { useToasts } from '@air/provider-toast';
import { resetSelectedItemsAction, selectedItemsCountSelector } from '@air/redux-selected-items';
import pluralize from 'pluralize';
import { useDispatch } from 'react-redux';

import { MoveItemsConfirmationModal } from '~/components/Modals/MoveItemsConfirmationModal/MoveItemsConfirmationModal';
import { ToastLink } from '~/components/UI/ToastLink';
import { useGoToBoardPage } from '~/hooks/useGoToBoardPage';
import { useItemParentChangeType } from '~/hooks/useItemParentChangeType';
import {
  SelectableGalleryAssetItem,
  SelectableGalleryBoardItem,
  SelectableGalleryFileItem,
} from '~/store/selectedItems/types';
import { useAddAssetsToBoards } from '~/swr-hooks/boards/useAddAssetsToBoards';
import { useMoveBoards } from '~/swr-hooks/boards/useMoveBoards';
import { useMoveAssetsToBoard } from '~/swr-hooks/clips/useMoveAssetsToBoard';
import { getBoardIdFromPath } from '~/utils/PathUtils';
import { useAirStore } from '~/utils/ReduxUtils';

import { DropLocation } from '../../dragTypes';

export interface HandleDragAssetsToMergeParams {
  board: Board;
  boards?: SelectableGalleryBoardItem<Board>[];
  assets?: (SelectableGalleryAssetItem | SelectableGalleryFileItem)[];
  dropLocation: DropLocation;
}

export const useHandleDragItemsToBoard = () => {
  const store = useAirStore();
  const dispatch = useDispatch();
  const { showToast } = useToasts();
  const { trackCompletedDragging } = useTrackCompletedDragging();
  const { goToBoardPage } = useGoToBoardPage();
  const {
    moveAssetsToBoard: { mutate: moveAssetsToBoard },
  } = useMoveAssetsToBoard();
  const {
    moveBoards: { mutate: moveBoards },
  } = useMoveBoards();
  const addAssetsToBoardMutation = useAddAssetsToBoards();
  const { getParentChangeType } = useItemParentChangeType();
  const [showMoveItemsConfirmationModal] = useAirModal(MoveItemsConfirmationModal);

  const handleDragItemsToBoard = ({ assets = [], boards = [], board, dropLocation }: HandleDragAssetsToMergeParams) => {
    // If a user is in a board and not searching,
    // we want to move the assets to another board, not "add" them
    const shouldMoveItemsInsteadOfAdd = getParentChangeType() === 'move';
    const currentBoardId = getBoardIdFromPath(window.location.pathname);
    const clipIds = assets?.map(({ id }) => id);
    const boardIds = boards?.map(({ id }) => id);
    const selectedCount = selectedItemsCountSelector(store.getState());

    if (boards.length > 0) {
      moveBoards({
        newParentId: board.id,
        boards: boards.map(({ item }) => ({
          ...item,
          ancestors: item.ancestors,
        })),
        library: board.library,
      });
    }

    const hasComments = assets?.some(({ item }) => item.openCommentCount);

    if (assets.length > 0) {
      const clipIds = assets.map(({ id }) => id);
      const clipsInfo = assets.map(({ id, item: { assetId } }) => ({ assetId, id }));

      if (shouldMoveItemsInsteadOfAdd) {
        /**
         * If there are discussions on the clips, show the confirmation modal
         * to let the user know that the discussions will be deleted when
         * the clips are moved.
         */
        if (hasComments) {
          showMoveItemsConfirmationModal({
            clips: assets.map(({ item }) => ({
              assetId: item.assetId,
              id: item.id,
              openCommentCount: item.openCommentCount,
            })),
            boards: [],
            selectedBoard: {
              id: board.id,
              title: board.title,
            },
          });
        } else {
          moveAssetsToBoard({
            clipsInfo,
            board,
            parentBoardId: currentBoardId!,
            trackLocation: 'drag-n-drop',
          });
        }
      } else {
        addAssetsToBoardMutation.mutate({
          clipIds,
          parentBoardId: currentBoardId,
          boards: [board],
          trackLocation: 'drag-n-drop',
        });
      }
    }

    trackCompletedDragging({
      numberOfBoards: boardIds?.length,
      boardIds,
      numberOfAssets: clipIds?.length,
      clipIds,
      dropLocation: {
        type: dropLocation,
        id: board.id,
      },
      usedSelecting: !!selectedCount,
    });

    dispatch(resetSelectedItemsAction());

    /**
     * If only assets are selected, choose text based on if assets should be moved or added.
     * If only boards are selected, conditionally show 'to' text based on if the board is
     * being moved to another board vs top level board, which doesn't have a `title`.
     * If both are selected, check if assets should be moved or added and formulate text
     * accordingly.
     */
    const toastMessage =
      assets.length > 0 && boards.length === 0
        ? `${pluralize('asset', assets.length, true)} ${shouldMoveItemsInsteadOfAdd ? 'moved to ' : 'added to '}`
        : assets.length === 0 && boards.length > 0
        ? `${pluralize('board', boards.length, true)} moved to `
        : `${pluralize('board', boards.length, true)} & ${pluralize('asset', assets.length, true)} moved to `;

    if (!(hasComments && shouldMoveItemsInsteadOfAdd)) {
      showToast(
        <>
          {toastMessage}
          <ToastLink onClick={() => goToBoardPage({ board, trackLocation: 'add-clips-to-board-toast' })}>
            {board.title}
          </ToastLink>
        </>,
      );
    }
  };

  return {
    handleDragItemsToBoard,
  };
};
