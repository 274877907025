import { ChevronRight } from '@air/next-icons';
import { ComponentProps, ReactNode } from 'react';

export type MobileSearchListItemProps = Pick<ComponentProps<'button'>, 'onClick'> & {
  label: string;
  meta?: ReactNode;
  thumbnail: ReactNode;
};

export const MobileSearchListItem = ({ label, meta, onClick, thumbnail }: MobileSearchListItemProps) => {
  return (
    <button
      className="flex cursor-pointer items-center gap-3 rounded p-1 text-left text-grey-12 hover:bg-grey-4"
      onClick={onClick}
    >
      <div className="flex min-h-[24px] min-w-[24px] items-center justify-center rounded text-14">{thumbnail}</div>
      <div className="flex grow flex-col gap-0.5 text-14 font-medium">
        <span>{label}</span>
        {!!meta && <div>{meta}</div>}
      </div>

      <ChevronRight className="size-6 text-grey-9" />
    </button>
  );
};
