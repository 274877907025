import { SidebarNavigationItem } from '@air/component-sidebar-navigation';
import { Clock } from '@air/next-icons';
import { Tooltip } from '@air/primitive-tooltip';
import Link from 'next/link';

import { Routes } from '~/constants/routes';
import { useIsActiveRoute } from '~/hooks/useIsActiveRoute';

export const SidebarNavigationItemMyUploads = () => {
  const { isActiveRoute } = useIsActiveRoute({ route: Routes.media.myUploads });

  return (
    <Tooltip label="Items uploaded by you" side="right" sideOffset={4}>
      <SidebarNavigationItem
        asChild
        data-id="my-uploads"
        prefix={<Clock className="size-4" />}
        state={isActiveRoute ? 'active' : 'default'}
      >
        <Link href={Routes.media.myUploads}>My uploads</Link>
      </SidebarNavigationItem>
    </Tooltip>
  );
};
