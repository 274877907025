import { useQueryClient } from '@tanstack/react-query';
import { memo, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useUnmount } from 'react-use';

import { DeleteBoardCompletedPanelItem } from '~/components/DeleteBoardTasks/DeleteBoardTaskCompletedPanelItem';
import { DeleteBoardFailedPanelItem } from '~/components/DeleteBoardTasks/DeleteBoardTaskFailedPanelItem';
import { DeleteBoardPanelInProgressPanelItem } from '~/components/DeleteBoardTasks/DeleteBoardTaskInProgressPanelItem';
import { PaneContainer } from '~/components/FileStatusTrackingPane/PaneContainer';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useSocketConnectionChange } from '~/providers/SocketContext/hooks/useSocketConnectionChange';
import { removeTasksAction } from '~/store/tasks/actions';
import { makeBoardDeletionTasksSelectorByWorkspaceId } from '~/store/tasks/selectors';
import { useGetSubnavSortValue } from '~/swr-hooks/subnav/useSubnavSort';
import { getWorkspaceStorageKey } from '~/swr-hooks/subscriptions/useStorage';
import { ReadyState } from '~/types/sockets';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { useRemoveBoardsFromAllViews } from '~/utils/mutateUtils/GalleryBoards';
import { useRemoveBoardsFromSideNav } from '~/utils/mutateUtils/SideNavBoards';
import { useAirSelector } from '~/utils/ReduxUtils';
import { useCancelTask } from '~/utils/taskUtils/useCancelTask';
import { useHideTask } from '~/utils/taskUtils/useHideTask';
import { usePrivateSyncTasks } from '~/utils/taskUtils/usePrivateSyncTasks';

import { getRecentlyDeletedKey } from '../RecentlyDeleted/hooks/queries/useRecentlyDeleted';

export const PrivateDeleteBoardTasksPanel = memo(() => {
  const { currentWorkspace } = useCurrentWorkspace();
  const tasksSelector = useMemo(
    () => makeBoardDeletionTasksSelectorByWorkspaceId(currentWorkspace?.id),
    [currentWorkspace?.id],
  );

  const dispatch = useDispatch();
  const boardDeletionTasks = useAirSelector(tasksSelector);
  const { cancelTask } = useCancelTask();
  const { hideTask } = useHideTask();

  const { removeBoardsFromAllViews } = useRemoveBoardsFromAllViews();
  const { removeBoardsFromSideNav } = useRemoveBoardsFromSideNav();
  const { getSubNavSortValue } = useGetSubnavSortValue();
  const queryClient = useQueryClient();
  const workspaceId = currentWorkspace?.id;

  const { loadFromStorage, syncLocalTasks } = usePrivateSyncTasks({
    workspaceId,
    tasksSelector,
    localType: 'BoardDeletion',
    remoteType: 'ContentRemover',
    onComplete: async ({ task, localTask, enrichMetadata }) => {
      const boardIds = localTask.metadata.boardIds;
      const boardSort = getSubNavSortValue()?.boardSort;

      queryClient.invalidateQueries({
        queryKey: getWorkspaceStorageKey(workspaceId),
      });
      queryClient.invalidateQueries({
        queryKey: getRecentlyDeletedKey({ workspaceId }),
      });

      await Promise.all([
        removeBoardsFromSideNav({
          workspaceId: workspaceId || '',
          boardIds,
          navSortParams: boardSort,
          parentId: '*',
        }),
        removeBoardsFromAllViews({
          parentBoardId: '*',
          boardsIdsToRemove: boardIds,
        }),
      ]);

      enrichMetadata({
        deletedBoardIds: task.data?.deletedBoardIds ?? [],
      });
    },
    onError({ localTask }) {
      reportErrorToBugsnag({
        error: new Error('Board deletion task failed. Task ID: ' + localTask.remoteTaskId),
        context: 'Failed to delete boards',
        metadata: { Data: { boardIds: localTask.metadata.boardIds, removeClips: localTask.metadata.removeClips } },
      });
    },
  });

  /**
   * On mount, take the tasks from local storage and add them to Redux
   */
  useEffect(() => {
    if (currentWorkspace?.id) {
      loadFromStorage();
    }
  }, [loadFromStorage, currentWorkspace?.id]);

  /**
   * Because sockets reconnect when the user's internet connection comes back online,
   * we don't need an explicit isOnline check but can piggyback off of the socket reconnecting
   */
  useSocketConnectionChange({
    onChange: (readyState) => {
      if (readyState === ReadyState.OPEN) {
        syncLocalTasks();
      }
    },
  });

  /**
   * When this panel unmounts (user logs out most likely),
   * reset the state
   */
  useUnmount(() => {
    dispatch(
      removeTasksAction({
        taskIds: boardDeletionTasks.map(({ localTaskId }) => localTaskId),
      }),
    );
  });

  if (boardDeletionTasks.length < 1) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2">
      {boardDeletionTasks.map((boardDeletionTask) => {
        return (
          <PaneContainer key={boardDeletionTask.localTaskId}>
            {!boardDeletionTask.hidden && boardDeletionTask.status === 'in-progress' && (
              <DeleteBoardPanelInProgressPanelItem
                onCancel={() => hideTask(boardDeletionTask.localTaskId)}
                {...boardDeletionTask}
              />
            )}
            {boardDeletionTask.status === 'completed' && (
              <DeleteBoardCompletedPanelItem
                onClear={() => cancelTask(boardDeletionTask.localTaskId)}
                {...boardDeletionTask}
              />
            )}
            {boardDeletionTask.status === 'error' && (
              <DeleteBoardFailedPanelItem
                onClear={() => cancelTask(boardDeletionTask.localTaskId)}
                {...boardDeletionTask}
              />
            )}
          </PaneContainer>
        );
      })}
    </div>
  );
});

PrivateDeleteBoardTasksPanel.displayName = 'PrivateDeleteBoardTasksPanel';
